import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'

switch (Capacitor.getPlatform()) {
  case 'web':
    // Do nothing
    break
  default:
    StatusBar.setOverlaysWebView({ overlay: false })
    StatusBar.setStyle({ style: Style.Dark })
}
