import { APP_URL } from '@goodfellas/environment'

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
)

interface Config {
  onSuccess?: (registration: ServiceWorkerRegistration) => void
  onUpdate?: (registration: ServiceWorkerRegistration) => void
}

export function register(config?: Config): void {
  if ('serviceWorker' in navigator) {
    const publicUrl = new URL(APP_URL, window.location.href)
    if (publicUrl.origin !== window.location.origin) return

    window.addEventListener('load', () => {
      const swUrl = new URL('./service-worker.js', APP_URL).toString()
      isLocalhost
        ? checkValidServiceWorker(swUrl, config)
        : registerValidSW(swUrl, config)
    })
  }
}

function registerValidSW(swUrl: string, config?: Config): void {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing
        if (!installingWorker) return

        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              if (config?.onUpdate) config.onUpdate(registration)
            } else {
              if (config?.onSuccess) config.onSuccess(registration)
            }
          }
        }
      }
    })
    .catch((error) =>
      console.error('Error during service worker registration:', error),
    )
}

function checkValidServiceWorker(swUrl: string, config?: Config): void {
  fetch(swUrl, { headers: { 'Service-Worker': 'script' } }).then((response) => {
    const contentType = response.headers.get('content-type')
    if (
      response.status === 404 ||
      (contentType && !contentType.includes('javascript'))
    ) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister().then(() => window.location.reload())
      })
    } else {
      registerValidSW(swUrl, config)
    }
  })
}

export function unregister(): void {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => registration.unregister())
      .catch((error) => console.error(error.message))
  }
}
