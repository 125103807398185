import 'abortcontroller-polyfill'
import 'globalthis/auto'
import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { createRoot } from 'react-dom/client'

import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { setupIonicReact } from '@ionic/react'
import '@ionic/react/css/core.css'
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/typography.css'

import { ScreenSize } from './components/hacks/screen-size'
import './components/status-bar'
import './i18n'
import * as ServiceWorker from './service-worker-register'
import './theme/global.css'

const container = document.getElementById('root')
if (container === null) {
  throw new Error('No root element found')
}

const App = React.lazy(() => import('./app'))

const root = createRoot(container)
root.render(
  <>
    <ScreenSize />
    <App />
  </>,
)

defineCustomElements(window)
setupIonicReact()

ServiceWorker.register()
