import React from 'react'

export const ScreenSize = () => {
  const { style } = document.querySelector(':root') as HTMLElement

  React.useEffect(() => {
    const handleResize = () => {
      style.setProperty('--full-height', `${window.innerHeight}px`)
      style.setProperty('--full-width', `${window.innerWidth}px`)
      style.setProperty(
        '--visual-height',
        `${window.visualViewport?.height ?? window.innerHeight}px`,
      )
      style.setProperty(
        '--visual-width',
        `${window.visualViewport?.width ?? window.innerWidth}px`,
      )
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    const timeout = setInterval(handleResize, 1000)

    return () => {
      clearTimeout(timeout)
      window.removeEventListener('resize', handleResize)
    }
  })

  return <></>
}
